<template>
  <div class="attachments">
    <Header
      back="返回"
      title="知识产权"
      index="首页"
      titleOne="科创模块"
      titleTwo="知识产权"
      titleThree="查新检测"
      beforeTitle="附件"
    />
    <div
      class="content"
      v-loading="loading"
      element-loading-text="附件上传中···"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.5)"
    >
      <h1>附件详情</h1>
      <ul>
        <li>
          <span>企业名称</span>
          <p>{{company_name }}</p>
        </li>
        <li>
          <span>查新检测</span>
          <p>{{name }}</p>
        </li>
      </ul>
      <div class="ImportTop">
        <el-button type="primary" class="ChooseFile" @click="dialogTableVisible = true">请上传附件</el-button>
        <span>（上传文件格式支持pdf，jpg，word，压缩包zip/rar）</span>
      </div>
      <el-table :data="fileList" style="width: 100%">
        <el-table-column prop="name" label="查新附件名称"></el-table-column>
        <el-table-column prop="create_time" label="上传时间">
          <template slot-scope="scope">{{scope.row.create_time | fmtdate}}</template>
        </el-table-column>
        <el-table-column prop="address" label="操作">
          <template slot-scope="scope">
            <el-button class="cz edit" @click="lookFile(scope.row)" type="text">查看</el-button>
            <el-button class="cz edit" type="text">
              <a :href="userSite + scope.row.url" target="_blank" :download="scope.row.name">下载</a>
            </el-button>
            <el-button class="cz del" @click="delFile(scope.row.id)" type="text">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-dialog title="上传附件" :visible.sync="dialogTableVisible">
        <el-upload
          class="upload-demo"
          ref="upload"
          action="#"
          :http-request="httpRequest"
          :show-file-list="true"
          :before-upload="beforeAvatarUpload"
          :file-list="fileList"
          :auto-upload="false"
        >
          <el-button type="primary" class="ChooseFile">请上传附件</el-button>
          <div slot="tip" class="el-upload__tip">上传txt/jpg/png/doc/xls/pdf/zip文件，单个文件不超过10mb</div>
        </el-upload>

        <el-button type="primary" class="submitUpload" @click="onSubmit()">确定</el-button>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import site from "../../components/global.vue";
export default {
  inject: ["reload"],
  data () {
    return {
      dialogTableVisible: false,
      loading: false,
      userSite: site.userSite,
      company_name: "",
      patent_name: "",
      name: '',
      tableData: [],
      fileList: [],
    };
  },
  created () {
    console.log(this.$route.query.data, 'data')
    this.company_name = this.$route.query.data.company_name
    this.name = this.$route.query.data.name
  },
  computed: {
    meber_id () {
      return this.$route.query.data.id;
    },
  },
  mounted () {
    if (this.meber_id) {
      this.getFileList();
    }
  },
  methods: {
    onSubmit () {
      this.$refs.upload.submit();
    },
    getFileList () {
      this.axios
        .get("/api/novelty/view_file", {
          params: {
            novelty_id: this.meber_id,
          },
        })
        .then((res) => {
          console.log(res, '附件查看');
          this.fileList = res.data.data
        });
    },
    httpRequest (param) {
      let file = param.file;
      const form = new FormData();
      console.log(form);
      form.append("file", file);
      form.append("novelty_id", this.meber_id);
      this.upload("/api/novelty/upload_file", form).then((res) => {
        if (res.code == 1) {
          this.$message({
            type: "success",
            message: res.message,
          });
          this.loading = false;
          this.dialogTableVisible = false
          this.getFileList()
        } else {
          this.$message({
            type: "error",
            message: res.message,
          });
          this.loading = false;
        }
      });
    },
    upload (url, params) {
      this.loading = true;
      return axios
        .post(url, params, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          return new Promise((resolve, reject) => {
            resolve(res);
          });
        })
        .catch((err) => {
          console.log("err:", err);
        });
    },
    // 上传文件前的过滤
    beforeAvatarUpload (file) {
      const isLt20M = file.size / 1024 / 1024 < 20;
      if (!isLt20M) {
        this.$message.error("上传文件大小不能超过 20MB!");
      }
      return isLt20M;
    },
    lookFile (row) {
      let url = this.userSite + row.url
      window.open(url, '_blank')
    },
    delFile (id) {
      // console.log(id, '查新检测附件删除id')
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.axios.delete("/api/file/delete_file", {
          params: {
            id: id,
          },
        });
        this.$message({
          type: "success",
          message: "删除成功!",
        });
        this.getFileList()
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
  },
};
</script>

<style scoped="scoped">
.attachments ul li {
  margin-top: 20px;
}

.attachments ul li span {
  color: #cbcfe3;
  margin-right: 20px;
  float: left;
}

.attachments ul li p {
  display: inline-block;
  width: 80%;
  margin: 0;
  color: #909399;
}

.upload-demo {
  display: inline-block;
}

.ImportTop {
  margin-top: 20px;
  display: flex;
  align-items: center;
}

.ChooseFile {
  width: 224px;
  height: 40px;
  background: #edeef4 !important;
  color: #909399 !important;
  border: none;
}

.el-upload-list {
  display: inline-block;
}

.el-upload-list__item-name {
  margin-top: 20px;
}
.ImportTop span {
  color: #a6abc7;
}
div/deep/.el-button--text {
  background: #f2f3f9;
  padding: 7px 15px !important;
  font-weight: bold;
}
div/deep/.el-form-item {
  margin: 0;
}
div/deep/.el-form-item__content {
  margin: 0;
  margin-right: 20px;
}
div/deep/.el-icon-loading {
  font-size: 20px;
  color: #fff;
}
div/deep/.el-loading-text {
  color: #fff;
  font-size: 12px;
}
.submitUpload {
  display: block;
  margin: 15px auto;
}
div/deep/.el-upload {
  display: flex;
  align-items: center;
}
.el-select {
  margin-right: 20px;
}
.upload-demo {
  width: 100%;
}
div/deep/.el-dialog__body {
  padding: 20px !important;
}
div/deep/.el-dialog__title {
  font-weight: bold;
}
</style>
